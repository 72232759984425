import * as React from "react"
import { useEffect } from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { getNoveltieAction,getNoveltiesAction } from "../redux/noveltiesDucks"
import { graphql, useStaticQuery } from 'gatsby';
import {connect} from "react-redux"
import Detail from '../components/Novedad/main'

const IndexPage = ({serverData,location}) => {
  

  const id = location.search.split("=")[1]

  console.log(serverData)

  return(
    <Layout preloader={true}>
      <Seo 
        title={serverData.title + " - " + "novedades"}
        meta={serverData.title + " - " + serverData.headline} 
        description={serverData.headline} 
        image={serverData.image}
      />
      <article>
        <Detail id={id} />
      </article>
    </Layout>
  )
}

export default IndexPage

export async function getServerData(context) {
  try {
    const res = await fetch("https://dev.mediacore.app/api/blog/novedades/30/get_data_detail/?client=8")
    if (!res.ok) {
      throw new Error(`Response failed`)
    }
    return {
      props: await res.json(),
    }
  } catch (error) {
    return {
      status: 500,
      headers: {},
      props: {}
    }
  }
}